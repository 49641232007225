/* eslint-disable */
import Swiper, { Navigation } from 'swiper';
export default {
  init() {
    jQuery('.hamburger').on('click', function(){
        jQuery('.mobile-nav-items').toggleClass('active');
        jQuery('.hamburger').toggleClass('active');
        jQuery('body').toggleClass('menu-open');
    });

    var swiper = new Swiper(".partners-swiper", {
      modules: [Navigation],
      loop: true,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is <= 499px
        499: {
            slidesPerView: 1,
        },
        // when window width is <= 999px
        999: {
            slidesPerView: 3,
        }
    }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
